import { ReactElement } from 'react';

import Icon, { IconProps } from '@components/atoms/Icon';

const CryptoCoins = ({ size, ...rest }: IconProps): ReactElement => (
    <Icon size={size} viewBox="0 0 31 30" {...rest}>
        <g clipPath="url(#clip0_1313_18412)">
            <path d="M27.4997 2.63245H24.3047C25.0697 2.63245 25.8047 3.86245 26.2847 5.84245C26.9951 8.85447 27.3277 11.9432 27.2747 15.0374C27.3284 18.1367 26.9959 21.2305 26.2847 24.2474C25.7597 26.2274 25.0697 27.4424 24.3047 27.4424H27.4997C29.1347 27.4424 30.4997 21.8925 30.4997 15.0374C30.4997 8.18245 29.1647 2.63245 27.4997 2.63245Z" fill="url(#paint0_linear_1313_18412)"/>
            <path d="M26.2847 5.9174C25.7597 3.9374 25.0697 2.7074 24.3047 2.7074C22.6697 2.7074 21.3047 8.2724 21.3047 15.1124C21.3047 21.9524 22.6397 27.5174 24.3047 27.5174C25.0697 27.5174 25.8047 26.3024 26.2847 24.3224C26.9959 21.3054 27.3284 18.2116 27.2747 15.1124C27.3277 12.0182 26.9951 8.92942 26.2847 5.9174Z" fill="url(#paint1_linear_1313_18412)"/>
            <path d="M25.7761 8.6175C25.3861 6.2325 24.7861 4.6875 24.1111 4.6875C22.9411 4.6875 21.9961 9.3225 21.9961 15.0375C21.9961 20.7525 23.0011 25.4175 24.1111 25.4175C24.7861 25.4175 25.3861 23.9175 25.7761 21.4875C26.0973 19.3578 26.2528 17.2063 26.2411 15.0525C26.253 12.8987 26.0975 10.7472 25.7761 8.6175Z" fill="url(#paint2_linear_1313_18412)"/>
            <path d="M26.2847 5.9174C25.7597 3.9374 25.0697 2.7074 24.3047 2.7074C22.6697 2.7074 21.3047 8.2724 21.3047 15.1124C21.3047 21.9524 22.6397 27.5174 24.3047 27.5174C25.0697 27.5174 25.8047 26.3024 26.2847 24.3224C26.9959 21.3054 27.3284 18.2116 27.2747 15.1124C27.3277 12.0182 26.9951 8.92942 26.2847 5.9174Z" fill="url(#paint3_radial_1313_18412)"/>
            <path d="M11.855 27.4425C18.1262 27.4425 23.21 21.8684 23.21 14.9925C23.21 8.11654 18.1262 2.54248 11.855 2.54248C5.58381 2.54248 0.5 8.11654 0.5 14.9925C0.5 21.8684 5.58381 27.4425 11.855 27.4425Z" fill="url(#paint4_linear_1313_18412)"/>
            <path d="M11.855 2.54248C5.585 2.54248 0.5 8.12248 0.5 14.9925C0.5 21.8625 5.585 27.4425 11.855 27.4425C18.125 27.4425 23.195 21.8775 23.195 14.9925C23.195 8.10748 18.125 2.54248 11.855 2.54248ZM11.855 25.2375C6.695 25.2375 2.51 20.6625 2.51 14.9925C2.51 9.32248 6.695 4.74748 11.855 4.74748C17.015 4.74748 21.185 9.33748 21.185 14.9925C21.185 20.6475 17 25.2375 11.855 25.2375Z" fill="url(#paint5_linear_1313_18412)"/>
            <path d="M8.00095 22.4175C7.90265 22.4436 7.79925 22.4436 7.70095 22.4175C6.51328 21.5924 5.54713 20.4875 4.88786 19.2003C4.2286 17.9132 3.89651 16.4835 3.92095 15.0375C3.83148 12.8406 4.61633 10.6978 6.10361 9.07834C7.59089 7.45892 9.65936 6.49494 11.856 6.39754C12.0167 6.38928 12.1742 6.44433 12.2948 6.55089C12.4154 6.65745 12.4894 6.80704 12.501 6.96754C12.503 7.04956 12.4885 7.13114 12.4585 7.2075C12.4285 7.28385 12.3835 7.35344 12.3262 7.41214C12.2689 7.47085 12.2004 7.51749 12.1248 7.54933C12.0492 7.58117 11.968 7.59757 11.886 7.59754C10.0209 7.69846 8.27122 8.53229 7.01815 9.91741C5.76508 11.3025 5.11012 13.1267 5.19595 14.9925C5.16595 16.2345 5.44493 17.4645 6.00783 18.572C6.57074 19.6795 7.39994 20.6298 8.42095 21.3375C8.55981 21.4294 8.65669 21.5724 8.69041 21.7354C8.72414 21.8984 8.69198 22.0682 8.60095 22.2075C8.52671 22.2902 8.43279 22.3528 8.3279 22.3895C8.22301 22.4262 8.11055 22.4359 8.00095 22.4175Z" fill="url(#paint6_linear_1313_18412)"/>
            <path d="M12.7713 23.5876C12.6222 23.5826 12.4793 23.5261 12.3672 23.4276C12.2551 23.3291 12.1805 23.1948 12.1563 23.0476C12.1542 22.8972 12.206 22.751 12.3023 22.6355C12.3986 22.5199 12.533 22.4426 12.6813 22.4176C14.3826 22.0875 15.9077 21.155 16.9768 19.7911C18.0459 18.4272 18.5872 16.7234 18.5013 14.9926C18.5272 13.6805 18.2083 12.3846 17.5766 11.2344C16.9449 10.0841 16.0224 9.11972 14.9013 8.43757C14.762 8.35052 14.6611 8.21357 14.6193 8.05468C14.5775 7.89579 14.5979 7.72693 14.6763 7.58257C14.7167 7.51063 14.7711 7.44756 14.8364 7.39709C14.9016 7.34662 14.9763 7.3098 15.0561 7.2888C15.1359 7.26781 15.2191 7.26308 15.3007 7.27489C15.3823 7.28671 15.4608 7.31482 15.5313 7.35757C16.8385 8.15236 17.9151 9.27508 18.6545 10.6144C19.3938 11.9536 19.7702 13.4629 19.7463 14.9926C19.8113 17.0159 19.1474 18.9952 17.8754 20.5701C16.6034 22.1449 14.808 23.2104 12.8163 23.5726L12.7713 23.5876Z" fill="url(#paint7_linear_1313_18412)"/>
            <path d="M11.8545 20.7376C10.6825 20.6899 9.56034 20.25 8.66817 19.4884C7.776 18.7268 7.16547 17.6876 6.93452 16.5376C6.89736 16.3767 6.92331 16.2077 7.00702 16.0653C7.09074 15.923 7.22585 15.8182 7.38452 15.7726C7.54742 15.736 7.71816 15.764 7.86083 15.8507C8.0035 15.9374 8.10698 16.0761 8.14952 16.2376C8.3154 17.1157 8.77021 17.9131 9.44159 18.503C10.113 19.0928 10.9623 19.4411 11.8545 19.4926C12.7819 19.4393 13.6623 19.0666 14.346 18.4376C15.0296 17.8086 15.4743 16.9623 15.6045 16.0426C15.6213 15.9628 15.6536 15.8871 15.6996 15.8198C15.7456 15.7525 15.8044 15.6949 15.8726 15.6503C15.9409 15.6058 16.0172 15.5751 16.0973 15.56C16.1774 15.5449 16.2597 15.5458 16.3395 15.5626C16.4193 15.5793 16.495 15.6116 16.5623 15.6576C16.6296 15.7036 16.6872 15.7624 16.7317 15.8306C16.7763 15.8989 16.807 15.9753 16.8221 16.0554C16.8371 16.1355 16.8363 16.2178 16.8195 16.2976C16.6332 17.4977 16.0366 18.5961 15.1313 19.4057C14.226 20.2153 13.0679 20.686 11.8545 20.7376Z" fill="url(#paint8_linear_1313_18412)"/>
            <path d="M16.1311 14.1076C15.9935 14.1084 15.8596 14.063 15.7508 13.9787C15.642 13.8944 15.5647 13.776 15.5311 13.6426C15.3531 12.785 14.8958 12.0107 14.2309 11.4407C13.5659 10.8708 12.7308 10.5373 11.8561 10.4926C11.0082 10.5335 10.1963 10.8479 9.54199 11.3887C8.88764 11.9294 8.42599 12.6676 8.22609 13.4926C8.17754 13.6512 8.06841 13.7842 7.92238 13.8628C7.77636 13.9415 7.60522 13.9593 7.44609 13.9126C7.28973 13.8608 7.1591 13.7511 7.081 13.6061C7.00291 13.4611 6.98326 13.2916 7.02609 13.1326C7.30885 12.0544 7.93122 11.0958 8.80111 10.3988C9.671 9.70184 10.7422 9.30343 11.8561 9.26257C13.0068 9.30341 14.111 9.72681 14.9939 10.4658C15.8769 11.2048 16.4882 12.2171 16.7311 13.3426C16.7728 13.5016 16.7506 13.6707 16.6693 13.8136C16.588 13.9565 16.4541 14.062 16.2961 14.1076H16.1311Z" fill="url(#paint9_linear_1313_18412)"/>
            <path d="M11.8571 17.7676C11.1805 17.7027 10.5559 17.3765 10.1161 16.8583C9.67632 16.3401 9.45603 15.6707 9.5021 14.9926C9.49791 14.5191 9.60585 14.0514 9.8171 13.6276C9.88871 13.4804 10.0159 13.3677 10.1706 13.3142C10.3253 13.2608 10.4949 13.271 10.6421 13.3426C10.7893 13.4142 10.902 13.5413 10.9555 13.6961C11.0089 13.8508 10.9987 14.0204 10.9271 14.1676C10.8076 14.4261 10.7461 14.7077 10.7471 14.9926C10.7471 15.8176 11.2721 16.4926 11.9021 16.4926C12.0506 16.5157 12.1859 16.5912 12.2837 16.7053C12.3815 16.8195 12.4352 16.9648 12.4352 17.1151C12.4352 17.2654 12.3815 17.4107 12.2837 17.5249C12.1859 17.639 12.0506 17.7145 11.9021 17.7376L11.8571 17.7676Z" fill="url(#paint10_linear_1313_18412)"/>
            <path d="M13.4741 16.4176H13.2641C13.1868 16.3907 13.1156 16.3487 13.0547 16.294C12.9938 16.2393 12.9444 16.173 12.9095 16.0989C12.8745 16.0249 12.8547 15.9446 12.8513 15.8628C12.8478 15.781 12.8606 15.6993 12.8891 15.6226C12.9676 15.4166 13.0083 15.1981 13.0091 14.9776C13.0385 14.6699 12.9721 14.3607 12.8191 14.0922C12.666 13.8237 12.4338 13.6091 12.1541 13.4776C12.0781 13.4493 12.0085 13.4062 11.9492 13.3508C11.8899 13.2954 11.8421 13.2289 11.8087 13.155C11.7752 13.0811 11.7567 13.0013 11.7542 12.9202C11.7517 12.8392 11.7653 12.7584 11.7941 12.6826C11.8545 12.528 11.9734 12.4034 12.1251 12.336C12.2768 12.2686 12.4489 12.2638 12.6041 12.3226C13.1104 12.5446 13.5384 12.9135 13.8325 13.3815C14.1267 13.8495 14.2736 14.3951 14.2541 14.9476C14.2517 15.3112 14.1857 15.6717 14.0591 16.0126C14.0138 16.1312 13.9337 16.2333 13.8293 16.3056C13.7249 16.3778 13.6011 16.4169 13.4741 16.4176Z" fill="url(#paint11_linear_1313_18412)"/>
            <path d="M17.7955 3.54748C16.3451 2.85439 14.7526 2.51022 13.1455 2.54248H11.8555C18.1255 2.54248 23.1955 8.12248 23.1955 14.9925C23.1955 21.8625 18.1255 27.4425 11.8555 27.4425H13.0705C15.0225 27.4569 16.9415 26.9382 18.6205 25.9425C20.5638 24.7376 22.1537 23.0401 23.2289 21.0222C24.3042 19.0043 24.8264 16.7378 24.7426 14.4529C24.6588 12.1679 23.972 9.9458 22.7519 8.01205C21.5318 6.07831 19.8218 4.50178 17.7955 3.44248V3.54748Z" fill="url(#paint12_linear_1313_18412)"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_1313_18412" x1="27.4097" y1="36.6374" x2="27.4097" y2="-3.20255" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1313_18412" x1="68.7347" y1="50.8574" x2="2.04469" y2="-2.8876" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1313_18412" x1="-22.9289" y1="8.2425" x2="30.0661" y2="15.9075" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <radialGradient id="paint3_radial_1313_18412" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.3114 14.9998) rotate(-92.38) scale(10.92 2.8392)">
                <stop stopColor="#2F0759" stopOpacity="0.5"/>
                <stop offset="0.97" stopColor="#2F0759" stopOpacity="0"/>
            </radialGradient>
            <linearGradient id="paint4_linear_1313_18412" x1="103.58" y1="76.3725" x2="0.0500019" y2="7.10247" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint5_linear_1313_18412" x1="-40.27" y1="-25.3275" x2="54.02" y2="47.6175" gradientUnits="userSpaceOnUse">
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint6_linear_1313_18412" x1="-26.019" y1="0.817541" x2="54.4709" y2="31.2375" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint7_linear_1313_18412" x1="-25.8387" y1="0.367569" x2="54.6513" y2="30.7876" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint8_linear_1313_18412" x1="-26.7855" y1="2.85754" x2="53.7045" y2="33.2775" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint9_linear_1313_18412" x1="-25.0589" y1="-1.70243" x2="55.4311" y2="28.7176" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint10_linear_1313_18412" x1="-26.2579" y1="1.46258" x2="54.2321" y2="31.8826" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint11_linear_1313_18412" x1="-25.6459" y1="-0.172414" x2="54.8591" y2="30.2476" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <linearGradient id="paint12_linear_1313_18412" x1="2.30047" y1="34.0875" x2="43.1305" y2="-25.3725" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9110DC"/>
                <stop offset="0.51" stopColor="#F48173"/>
                <stop offset="1" stopColor="#F4E145"/>
            </linearGradient>
            <clipPath id="clip0_1313_18412">
                <rect width="30" height="24.915" fill="white" transform="translate(0.5 2.54248)"/>
            </clipPath>
        </defs>
    </Icon>
);

export default CryptoCoins;
