import { ReactElement } from 'react';

import Icon, { IconProps } from '@components/atoms/Icon';

const WeChat = ({ size, ...rest }: IconProps): ReactElement => (
    <Icon size={size} viewBox="0 0 30 30" {...rest}>
        <g clipPath="url(#clip0_1313_18451)">
            <path d="M0 11.8696C0 14.5944 1.47437 17.0842 3.75303 18.7326C3.95426 18.8673 4.05487 19.0691 4.05487 19.3381C4.05487 19.4055 4.02143 19.5062 4.02143 19.5736C3.8538 20.2464 3.55196 21.3566 3.51761 21.3901C3.48417 21.4913 3.45074 21.5586 3.45074 21.6594C3.45146 21.7571 3.49049 21.8506 3.55944 21.9198C3.62839 21.989 3.72176 22.0284 3.81945 22.0295C3.8907 22.0225 3.95945 21.9995 4.02052 21.9622L6.39949 20.583C6.57087 20.4768 6.76766 20.4186 6.96927 20.4145C7.08209 20.409 7.19511 20.4204 7.30454 20.4484C8.41021 20.7846 9.61664 20.9522 10.8565 20.9522C16.8566 20.9522 21.7144 16.8813 21.7144 11.8687C21.7144 6.85612 16.8561 2.78613 10.8574 2.78613C4.8587 2.78613 0 6.85658 0 11.8696Z" fill="url(#paint0_linear_1313_18451)"/>
            <path d="M20.9755 26.2987C22.012 26.2987 23.0146 26.1613 23.9173 25.892C23.9842 25.8581 24.0845 25.8581 24.1848 25.8581C24.352 25.8581 24.5192 25.9259 24.6529 25.9955L26.6252 27.1479C26.692 27.1818 26.7255 27.2157 26.7923 27.2157C26.8322 27.2156 26.8716 27.2076 26.9083 27.1921C26.945 27.1767 26.9783 27.1542 27.0063 27.1258C27.0343 27.0975 27.0564 27.0638 27.0713 27.0269C27.0862 26.99 27.0937 26.9505 27.0933 26.9106C27.0933 26.8428 27.0598 26.7732 27.0598 26.6734C27.0598 26.6395 26.7923 25.7244 26.6586 25.1482C26.6313 25.084 26.6199 25.0143 26.6252 24.9448C26.6252 24.7414 26.7255 24.572 26.8927 24.4364C28.798 23.0468 30.0017 20.9788 30.0017 18.674C30.0017 14.4712 25.9565 11.0475 20.9755 11.0475C15.9945 11.0475 11.9492 14.4369 11.9492 18.674C11.9492 22.8773 15.9945 26.3005 20.9755 26.3005V26.2987Z" fill="url(#paint1_linear_1313_18451)"/>
            <path d="M8.63457 8.9369C8.63663 9.12118 8.60204 9.30403 8.53279 9.47482C8.46354 9.64561 8.36102 9.80092 8.23119 9.93173C8.10137 10.0625 7.94683 10.1662 7.77657 10.2367C7.60631 10.3073 7.42372 10.3432 7.23943 10.3426C7.0551 10.3433 6.87247 10.3074 6.70215 10.2369C6.53184 10.1664 6.37724 10.0627 6.24737 9.93189C6.1175 9.80108 6.01494 9.64575 5.94566 9.47493C5.87638 9.30411 5.84177 9.12122 5.84384 8.9369C5.84177 8.75256 5.87638 8.56965 5.94565 8.3988C6.01493 8.22796 6.11748 8.0726 6.24734 7.94175C6.37721 7.8109 6.5318 7.70719 6.70211 7.63663C6.87243 7.56608 7.05508 7.53009 7.23943 7.53077C7.42375 7.53015 7.60634 7.56618 7.77661 7.63676C7.94687 7.70735 8.1014 7.81107 8.23122 7.94191C8.36104 8.07275 8.46355 8.2281 8.53279 8.39891C8.60204 8.56972 8.63663 8.7526 8.63457 8.9369ZM15.8713 8.9369C15.8733 9.1212 15.8387 9.30407 15.7694 9.47486C15.7001 9.64565 15.5975 9.80095 15.4676 9.93175C15.3378 10.0625 15.1832 10.1662 15.0129 10.2368C14.8426 10.3073 14.66 10.3432 14.4757 10.3426C14.2914 10.3432 14.1088 10.3073 13.9386 10.2367C13.7683 10.1662 13.6138 10.0625 13.484 9.93173C13.3541 9.80092 13.2516 9.64561 13.1824 9.47482C13.1131 9.30403 13.0785 9.12118 13.0806 8.9369C13.0785 8.7526 13.1131 8.56972 13.1824 8.39891C13.2516 8.2281 13.3541 8.07275 13.4839 7.94191C13.6138 7.81107 13.7683 7.70735 13.9385 7.63676C14.1088 7.56618 14.2914 7.53015 14.4757 7.53077C14.6601 7.53015 14.8427 7.56618 15.013 7.63676C15.1833 7.70733 15.3378 7.81105 15.4677 7.94189C15.5975 8.07272 15.7001 8.22806 15.7694 8.39888C15.8387 8.56969 15.8733 8.75258 15.8713 8.9369Z" fill="#187E28"/>
            <path d="M22.847 16.3179C22.8458 16.479 22.8764 16.6388 22.9372 16.7881C22.998 16.9373 23.0878 17.073 23.2013 17.1874C23.3148 17.3018 23.4499 17.3925 23.5986 17.4544C23.7474 17.5164 23.907 17.5482 24.0681 17.5481C24.2292 17.5481 24.3887 17.5163 24.5375 17.4543C24.6862 17.3924 24.8212 17.3016 24.9347 17.1872C25.0481 17.0729 25.1378 16.9372 25.1986 16.788C25.2594 16.6388 25.29 16.479 25.2888 16.3179C25.29 16.1568 25.2594 15.997 25.1986 15.8478C25.1378 15.6986 25.0481 15.5629 24.9347 15.4486C24.8212 15.3342 24.6862 15.2434 24.5375 15.1815C24.3887 15.1195 24.2292 15.0876 24.0681 15.0876C23.907 15.0876 23.7474 15.1194 23.5986 15.1813C23.4499 15.2433 23.3148 15.334 23.2013 15.4484C23.0878 15.5628 22.998 15.6985 22.9372 15.8477C22.8764 15.997 22.8458 16.1568 22.847 16.3179ZM16.8295 16.3179C16.8283 16.479 16.8589 16.6388 16.9197 16.7881C16.9805 16.9373 17.0703 17.073 17.1838 17.1874C17.2973 17.3018 17.4324 17.3925 17.5811 17.4544C17.7299 17.5164 17.8895 17.5482 18.0506 17.5481C18.2117 17.5481 18.3712 17.5163 18.52 17.4543C18.6687 17.3924 18.8037 17.3016 18.9172 17.1872C19.0306 17.0729 19.1203 16.9372 19.1811 16.788C19.2419 16.6388 19.2725 16.479 19.2713 16.3179C19.2725 16.1568 19.2419 15.997 19.1811 15.8478C19.1203 15.6986 19.0306 15.5629 18.9172 15.4486C18.8037 15.3342 18.6687 15.2434 18.52 15.1815C18.3712 15.1195 18.2117 15.0876 18.0506 15.0876C17.8894 15.0874 17.7296 15.1191 17.5807 15.1809C17.4318 15.2428 17.2966 15.3335 17.1829 15.4479C17.0692 15.5623 16.9794 15.6981 16.9185 15.8474C16.8576 15.9967 16.8269 16.1566 16.8282 16.3179" fill="#858C8C"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_1313_18451" x1="10.866" y1="20.9071" x2="10.866" y2="2.86949" gradientUnits="userSpaceOnUse">
                <stop stopColor="#78D431"/>
                <stop offset="1" stopColor="#9EEE69"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1313_18451" x1="20.9744" y1="26.1681" x2="20.9744" y2="11.0403" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E4E6E6"/>
                <stop offset="1" stopColor="#F5F5FF"/>
            </linearGradient>
            <clipPath id="clip0_1313_18451">
                <rect width="30" height="24.4277" fill="white" transform="translate(0 2.78613)"/>
            </clipPath>
        </defs>
    </Icon>
);

export default WeChat;
